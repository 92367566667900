import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core'
import { TspDeviceListData } from '@ti-platform/contracts'

@Component({
  selector: 'app-device-edit',
  templateUrl: 'device-edit.component.html',
  styleUrl: 'device-edit.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeviceEditComponent {
  @Input() opened = false
  @Input({ required: true }) device!: TspDeviceListData | null

  @Output() cancelEvent = new EventEmitter<void>()
  @Output() deviceEditedEvent = new EventEmitter<boolean>()

  public async onCancel() {
    this.cancelEvent.emit()
  }

  public onSubmit(result: boolean) {
    this.deviceEditedEvent.emit(result)
  }
}
