import { Component, EventEmitter, inject, Input, OnInit, Output } from '@angular/core'
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms'
import {
  ApiErrors,
  CommandFormData,
  CommandMainData,
  CreateDeviceData,
  defaultBatteryParameter,
  defaultIgnitionParameter,
  defaultOdometerParameter,
  DeviceSensorsConfig,
  DoubleDeviceCheckData,
  EditDeviceData,
  FleetDisplayData,
  OdometerSourceTypes,
  phoneOrIccidPattern,
  SensorOption,
  SensorOptionsData,
  TspDeviceListData,
} from '@ti-platform/contracts'
import { cloneDeep } from 'lodash'
import { BehaviorSubject, takeUntil } from 'rxjs'

import {
  CommandsListModel,
  DevicesListModel,
  DevicesTypesDataProvider,
  DeviceTypeDataOption,
} from '@ti-platform/tsp-admin/app/devices/models'
import { DeviceProcessingStatuses } from '@ti-platform/web/api/scopes/tsp/routes/devices.route'
import {
  checkExistApiError,
  getApiErrorKey,
  getMessageFromException,
  injectDestroy$,
} from '@ti-platform/web/common'
import { LanguageService } from '@ti-platform/web/ui-kit/i18n'
import { DialogFacade } from '@ti-platform/web/ui-kit/layout/services'
import { MessageService } from 'primeng/api'
import { AutoCompleteCompleteEvent } from 'primeng/autocomplete'

enum DeviceFormTabs {
  configuration = 0,
  sensors = 1,
  commands = 2,
}

const getNullSensorOptionByParam = (param: string): SensorOption => {
  return {
    value: param,
    label: param,
    data: null,
  }
}

@Component({
  selector: 'app-device-form',
  templateUrl: 'device-form.component.html',
  styleUrl: 'device-form.component.scss',
})
export class DeviceFormComponent implements OnInit {
  @Output() cancelEvent = new EventEmitter<void>()
  @Output() submitEvent = new EventEmitter<boolean>()
  @Input() header = ''
  protected readonly destroy$ = injectDestroy$()
  protected readonly devicesViewModel = inject(DevicesListModel)
  protected readonly devicesTypesDataProvider = inject(DevicesTypesDataProvider)
  protected readonly languageService = inject(LanguageService)
  protected readonly dialogFacade = inject(DialogFacade)
  protected readonly messageService = inject(MessageService)
  protected filteredTypesData: DeviceTypeDataOption[] = []
  protected readonly disableMode$ = new BehaviorSubject<boolean>(false)
  protected filteredFleets: FleetDisplayData[] = []
  protected readonly videoChannels: { value: number; name: string }[] = []
  protected readonly errorMessageKey$ = new BehaviorSubject<string>('')
  protected readonly modelWasChanged$ = new BehaviorSubject<boolean>(false)
  protected readonly isLoading$ = new BehaviorSubject<boolean>(false)
  protected readonly sensorsOptions$ = new BehaviorSubject<SensorOptionsData | null>(null)
  protected readonly commandsListModel = inject(CommandsListModel)
  protected readonly commandListToAssign$ = new BehaviorSubject<CommandMainData[]>([])
  protected readonly isLoadingCommandList$ = new BehaviorSubject<boolean>(false)
  protected readonly isErrorLoadCommandList$ = new BehaviorSubject<boolean>(false)
  protected readonly showAddCommandDialog$ = new BehaviorSubject(false)
  protected commandSearch = ''

  protected currentTab: DeviceFormTabs = DeviceFormTabs.configuration
  protected readonly OdometerSourceTypes = OdometerSourceTypes

  protected readonly deviceEditForm = new FormGroup({
    name: new FormControl<string>('', [Validators.required]),
    deviceType: new FormControl<DeviceTypeDataOption | null>(null, [Validators.required]),
    isVideo: new FormControl<boolean>(false),
    videoChannelsNumber: new FormControl<number | null>(null),
    identifier: new FormControl<string>(''),
    sim: new FormControl<string>('', [Validators.pattern(phoneOrIccidPattern)]),
    password: new FormControl<string | null>(null),
    assignToFleet: new FormControl<boolean>(false),
    fleet: new FormControl<FleetDisplayData | null>(null),
    ignitionParameter: new FormControl<SensorOption>(
      cloneDeep(getNullSensorOptionByParam(defaultIgnitionParameter)),
    ),
    batteryParameter: new FormControl<SensorOption>(
      cloneDeep(getNullSensorOptionByParam(defaultBatteryParameter)),
    ),
    odometerSource: new FormControl<OdometerSourceTypes>(OdometerSourceTypes.calculator),
    odometerParameter: new FormControl<SensorOption>(
      cloneDeep(getNullSensorOptionByParam(defaultOdometerParameter)),
    ),
    assignCommandIdsStatuses: new FormGroup({}),
  })

  protected _device: Partial<TspDeviceListData> = {}

  get device(): Partial<TspDeviceListData> {
    return this._device
  }

  @Input()
  set device(value: Partial<TspDeviceListData>) {
    this.sensorsOptions$.next(null)
    this.resetCommands()
    this.currentTab = DeviceFormTabs.configuration
    this._device = value
    // set start values
    let deviceType: DeviceTypeDataOption | null = null
    if (this.device.externalDeviceTypeId && this.allTypesDataOptions.length) {
      deviceType =
        this.allTypesDataOptions.find((item) => item.value === this.device.externalDeviceTypeId) ??
        null
    }
    deviceType = deviceType ?? null

    const ignitionParameter: SensorOption = this.getNullSensorOptionByParam(
      this.currentIgnitionParameter,
    )

    const batteryParameter: SensorOption = this.getNullSensorOptionByParam(
      this.currentBatteryParameter,
    )

    const odometerSource = this.currentOdometerSource

    const odometerParameter: SensorOption = this.getNullSensorOptionByParam(
      this.currentOdometerParameter,
    )

    this.deviceEditForm.setValue(
      {
        name: this.device.name || '',
        deviceType,
        isVideo: this.device.isVideo || false,
        videoChannelsNumber: this.device.videoChannelsNumber || null,
        identifier: this.device.identifier || '',
        sim: this.device.sim || '',
        password: this.device.config?.password || null,
        assignToFleet: !!this.device.fleetId,
        fleet: this.device.fleet || null,
        ignitionParameter,
        batteryParameter,
        odometerSource,
        odometerParameter,
        assignCommandIdsStatuses: {},
      },
      { emitEvent: false },
    )
    this.modelWasChanged$.next(false)

    if (this.device.id) {
      this.devicesViewModel.getDeviceSensorsOptions(this.device.id).then((value) => {
        if (value) {
          this.sensorsOptions$.next(value)
          if (this.currentIgnitionParameter && value?.ignitionOptions) {
            const currentOption = value.ignitionOptions.find(
              (item) => item.value === this.currentIgnitionParameter,
            )
            if (currentOption) {
              this.deviceEditForm.patchValue(
                { ignitionParameter: currentOption },
                { emitEvent: false },
              )
            }
          }
          if (this.currentBatteryParameter && value?.batteryOptions) {
            const currentOption = value.batteryOptions.find(
              (item) => item.value === this.currentBatteryParameter,
            )
            if (currentOption) {
              this.deviceEditForm.patchValue(
                { batteryParameter: currentOption },
                { emitEvent: false },
              )
            }
          }
          if (this.currentOdometerParameter && value?.odometerOptions) {
            const currentOption = value.odometerOptions.find(
              (item) => item.value === this.currentOdometerParameter,
            )
            if (currentOption) {
              this.deviceEditForm.patchValue(
                { odometerParameter: currentOption },
                { emitEvent: false },
              )
            }
          }
        }
      })

      if (this.commandDescription) {
        this.getCommandsListToAssign().then(() => {
          if (this.device.deviceCommands?.length) {
            this.device.deviceCommands?.forEach((item) => {
              this.deviceEditForm
                .get(['assignCommandIdsStatuses', item.commandId])
                ?.setValue(true, { emitEvent: false })
            })
          }
        })
      }
    }
  }

  get commandListToAssign() {
    return this.commandListToAssign$.getValue()
  }

  get filteredCommandListToAssign() {
    const allList = this.commandListToAssign$.getValue()
    let result = allList
    if (this.commandSearch && allList?.length) {
      result = allList.filter((item) =>
        item.name.toLowerCase().includes(this.commandSearch.toLowerCase()),
      )
    }
    return result.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
  }

  get newDeviceMode() {
    return !this._device.id
  }

  get allTypesDataOptions() {
    return this.devicesTypesDataProvider.deviceTypesDataOptions
  }

  get deviceType() {
    return this.deviceEditForm.get('deviceType')?.value
  }

  get deviceTypeData() {
    return this.deviceType?.value
      ? this.devicesTypesDataProvider.deviceTypeDataByTypeId[this.deviceType?.value]
      : null
  }

  public get commandDescription() {
    return this.deviceType?.value
      ? this.devicesTypesDataProvider.commandDataByDeviceTypeId[this.deviceType?.value]
      : null
  }

  get ignitionParameter() {
    return this.deviceEditForm.get('ignitionParameter')?.value
  }

  get batteryParameter() {
    return this.deviceEditForm.get('batteryParameter')?.value
  }

  get odometerSource() {
    return this.deviceEditForm.get('odometerSource')?.value
  }

  get odometerParameter() {
    return this.deviceEditForm.get('odometerParameter')?.value
  }

  get withPassword() {
    return !!this.deviceTypeData?.configuration?.properties?.password
  }

  get configWithPhone() {
    return !!this.deviceTypeData?.configuration?.properties?.phone
  }

  get identPattern(): string | null {
    return this.deviceTypeData?.configuration?.properties?.ident?.pattern ?? null
  }

  get identifierTooltipKey() {
    return this.identPattern ? `tsp-admin.devices.ident-check.${this.identPattern}` : ''
  }

  get fleet() {
    return this.deviceEditForm.get('fleet')?.value
  }

  get isValidForm() {
    return this.deviceEditForm.valid
  }

  get sensorsOptions() {
    return this.sensorsOptions$.getValue()
  }

  get currentIgnitionParameter() {
    return this.device?.sensorsConfig?.ignition_parameter ?? defaultIgnitionParameter
  }

  get currentBatteryParameter() {
    return this.device?.sensorsConfig?.battery_parameter ?? defaultBatteryParameter
  }

  get currentOdometerSource() {
    return this.device?.sensorsConfig?.odometer_source_type ?? OdometerSourceTypes.calculator
  }

  get currentOdometerParameter() {
    return this.device?.sensorsConfig?.odometer_parameter ?? defaultOdometerParameter
  }

  get ignitionOptions() {
    const defaultValue = [getNullSensorOptionByParam(defaultIgnitionParameter)]
    if (!this.device.id || this.device.externalDeviceTypeId !== this.deviceType?.value) {
      return defaultValue
    }

    const ignitionOptions = this.sensorsOptions?.ignitionOptions
    const result: SensorOption[] = ignitionOptions ?? defaultValue

    if (!result.some((item) => item.value === this.currentIgnitionParameter)) {
      result.push(this.getNullSensorOptionByParam(this.currentIgnitionParameter))
    }

    return result.sort((a, b) =>
      a.label.toLocaleLowerCase().localeCompare(b.label.toLocaleLowerCase()),
    )
  }

  get batteryOptions() {
    const defaultValue = [getNullSensorOptionByParam(defaultBatteryParameter)]
    if (!this.device.id || this.device.externalDeviceTypeId !== this.deviceType?.value) {
      return defaultValue
    }

    const batteryOptions = this.sensorsOptions?.batteryOptions
    const result: SensorOption[] = batteryOptions ?? defaultValue

    if (!result.some((item) => item.value === this.currentBatteryParameter)) {
      result.push(this.getNullSensorOptionByParam(this.currentBatteryParameter))
    }

    return result.sort((a, b) =>
      a.label.toLocaleLowerCase().localeCompare(b.label.toLocaleLowerCase()),
    )
  }

  get odometerOptions() {
    const defaultValue = [getNullSensorOptionByParam(defaultOdometerParameter)]
    if (!this.device.id || this.device.externalDeviceTypeId !== this.deviceType?.value) {
      return defaultValue
    }

    const odometerOptions = this.sensorsOptions?.odometerOptions
    const result: SensorOption[] = odometerOptions ?? defaultValue

    if (!result.some((item) => item.value === this.currentOdometerParameter)) {
      result.push(this.getNullSensorOptionByParam(this.currentOdometerParameter))
    }

    return result.sort((a, b) =>
      a.label.toLocaleLowerCase().localeCompare(b.label.toLocaleLowerCase()),
    )
  }

  protected getNullSensorOptionByParam(param: string): SensorOption {
    return {
      value: param,
      label: param,
      data: null,
    }
  }

  protected formatOdometerValue(value: number) {
    return Math.floor(value * 100) / 100
  }

  protected resetCommands() {
    this.commandListToAssign$.next([])
    this.deviceEditForm.setControl('assignCommandIdsStatuses', new FormGroup({}), {
      emitEvent: false,
    })
    this.commandSearch = ''
    this.isLoadingCommandList$.next(false)
    this.isErrorLoadCommandList$.next(false)
  }

  protected resetForm() {
    this.deviceEditForm.reset()
    this.resetCommands()
  }

  public onCancel() {
    if (!this.disableMode$.getValue()) {
      this.resetForm()
      this.cancelEvent.emit()
    }
  }

  ngOnInit() {
    // set video channels list
    for (let i = 1; i <= 16; i++) {
      this.videoChannels.push({ value: i, name: i.toString() })
    }

    this.doInitFormConnectedActions()

    this.devicesViewModel.selectedFleet$.pipe(takeUntil(this.destroy$)).subscribe((fleet) => {
      if (fleet) {
        this.deviceEditForm.get('assignToFleet')?.setValue(true)
        this.deviceEditForm.get('fleet')?.setValue({
          id: fleet.id,
          name: fleet.name,
          isDemo: !!fleet.isDemo,
        })
      }
    })
  }

  protected doInitFormConnectedActions() {
    this.deviceEditForm
      .get('videoChannelsNumber')
      ?.setValidators(this.videoChannelsNumberValidator(this.deviceEditForm))

    this.deviceEditForm.get('fleet')?.setValidators(this.fleetValidator(this.deviceEditForm))
    this.deviceEditForm
      .get('identifier')
      ?.setValidators([Validators.required, this.identPatternValidator(this.deviceEditForm)])

    this.deviceEditForm.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.errorMessageKey$.next('')
      this.modelWasChanged$.next(true)
    })

    this.deviceEditForm.controls['deviceType'].valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.deviceEditForm.controls['identifier'].updateValueAndValidity()
        if (
          this.deviceEditForm.get('identifier')?.value &&
          !this.deviceEditForm.get('identifier')?.valid
        ) {
          this.deviceEditForm.get('identifier')?.markAsDirty()
        }
        this.deviceEditForm
          .get('ignitionParameter')
          ?.setValue(getNullSensorOptionByParam(defaultIgnitionParameter))
        this.deviceEditForm
          .get('batteryParameter')
          ?.setValue(getNullSensorOptionByParam(defaultBatteryParameter))
        this.deviceEditForm.get('odometerSource')?.setValue(OdometerSourceTypes.calculator)
        this.deviceEditForm
          .get('odometerParameter')
          ?.setValue(getNullSensorOptionByParam(defaultOdometerParameter))
        this.resetCommands()
        if (this.commandDescription) {
          this.getCommandsListToAssign().then()
        }
      })

    this.deviceEditForm.controls['assignToFleet'].valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.deviceEditForm.controls['fleet'].updateValueAndValidity()
      })

    this.deviceEditForm.controls['isVideo'].valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.deviceEditForm.controls['videoChannelsNumber'].updateValueAndValidity()
      })
  }

  protected videoChannelsNumberValidator(form: FormGroup): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const needRequire = form.get('isVideo')?.value === true
      const noValue = needRequire ? !control.value : false
      return noValue ? { required: control.value } : null
    }
  }

  protected fleetValidator(form: FormGroup): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const needRequire = form.get('assignToFleet')?.value === true
      const noValue = needRequire ? !control.value : false
      return noValue ? { required: control.value } : null
    }
  }

  protected identPatternValidator(form: FormGroup): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!this.identPattern) {
        return null
      }
      if (control.value) {
        const matchResult = control.value.match(new RegExp(this.identPattern))
        return matchResult ? null : { pattern: control.value }
      } else {
        return null
      }
    }
  }

  protected onFilterTypesData(event: AutoCompleteCompleteEvent) {
    const query = event.query
    if (query === '') {
      this.filteredTypesData = [...this.allTypesDataOptions]
    } else {
      this.filteredTypesData = this.allTypesDataOptions.filter((value) =>
        value.label.toLowerCase().includes(query.toLowerCase()),
      )
    }
  }

  protected async onFilterFleet(event: AutoCompleteCompleteEvent) {
    const query = event.query
    const allFleetCount = this.devicesViewModel.allFleetsCount$.value
    const recentFleets = this.devicesViewModel.recentFleetsList$.value
    if (this.device.fleet?.name === query) {
      const find = recentFleets.find((value) => value.name === query)
      this.filteredFleets = find ? [...recentFleets] : [{ ...this.device.fleet }, ...recentFleets]
    } else if (!query.length || allFleetCount >= recentFleets.length) {
      this.filteredFleets = query
        ? recentFleets.filter((fleet) => fleet.name.toLowerCase().includes(query.toLowerCase()))
        : [...recentFleets]
    } else {
      try {
        const result = await this.devicesViewModel.getAssignFleetSearch(query)
        this.filteredFleets = result ?? []
      } catch (e) {
        this.filteredFleets = []
        console.error('Error get searched data')
      }
    }
  }

  protected getSensorsConfigFromForm(data) {
    const sensorsConfig: DeviceSensorsConfig = {}
    if (data.odometerSource) {
      sensorsConfig.odometer_source_type = data.odometerSource
    }
    if (data.odometerSource === OdometerSourceTypes.telemetry) {
      sensorsConfig.odometer_parameter = data.odometerParameter?.value
    }
    if (data.ignitionParameter) {
      sensorsConfig.ignition_parameter = data.ignitionParameter?.value
    }
    if (data.batteryParameter) {
      sensorsConfig.battery_parameter = data.batteryParameter?.value
    }
    return sensorsConfig
  }

  protected getCommandIdsToSet(data) {
    const result: string[] = []
    Object.keys(data.assignCommandIdsStatuses).forEach((commandId) => {
      if (data.assignCommandIdsStatuses[commandId]) {
        result.push(commandId)
      }
    })
    return result
  }

  protected async createDevice() {
    let result: boolean | null = null
    const data = this.deviceEditForm.getRawValue()
    const deviceData: CreateDeviceData = {
      name: data.name!,
      identifier: data.identifier!,
      deviceTypeId: data.deviceType!.value,
      isVideo: !!data.isVideo,
      config: {
        ident: data.identifier!,
      },
      sensorsConfig: this.getSensorsConfigFromForm(data),
    }
    if (deviceData.isVideo) {
      deviceData.videoChannelsNumber = data.videoChannelsNumber!
    }
    if (data.password && this.withPassword) {
      deviceData.config.password = data.password
    }
    if (data.sim) {
      deviceData.sim = data.sim
      if (this.configWithPhone) {
        deviceData.config.phone = data.sim
      }
    }
    if (data.assignToFleet) {
      deviceData.fleetId = data.fleet!.id
    }

    let deviceId: string | null = null
    try {
      const createResult = await this.devicesViewModel.createDevice(deviceData)
      if (createResult.status === DeviceProcessingStatuses.done_completely) {
        result = true
        deviceId = createResult.device?.id
      } else if (createResult.status === DeviceProcessingStatuses.done_partially) {
        result = false
      } else {
        this.errorMessageKey$.next(getApiErrorKey(ApiErrors.unknown))
      }
    } catch (e) {
      this.processErrorOnException(e)
    }
    if (result === true && deviceId) {
      const commandIds = this.getCommandIdsToSet(data)
      if (commandIds?.length) {
        try {
          await this.devicesViewModel.setDeviceCommands(deviceId, commandIds)
          this.commandsListModel.refresh()
        } catch (e) {
          result = false
          const message = getMessageFromException(e)
          console.error(`Error to set device commands: ${message}`)
        }
      }
    }

    if (result !== null) {
      this.resetForm()
      this.submitEvent.emit(result)
    }
  }

  protected async getEditDialogLabels(
    deviceName: string,
    fleetName?: string,
    newFleetName?: string,
  ) {
    const [summary, description, confirmButton, cancelButton] = await Promise.all([
      fleetName && newFleetName
        ? this.languageService.translate('tsp-admin.devices.edit.dialog.reassign-question', {
            fleetName,
            newFleetName,
          })
        : this.languageService.translate('tsp-admin.devices.edit.dialog.unassign-question', {
            deviceName,
          }),
      fleetName && newFleetName
        ? this.languageService.translate('tsp-admin.devices.edit.dialog.reassign-warn', {
            fleetName,
          })
        : this.languageService.translate('tsp-admin.devices.edit.dialog.unassign-warn'),
      this.languageService.translate('tsp-admin.devices.edit.dialog.unassign-button'),
      this.languageService.translate('tsp-admin.devices.edit.dialog.cancel-button'),
    ])
    return {
      summary,
      description,
      confirmButton,
      cancelButton,
    }
  }

  protected async editDevice() {
    let result: any = null
    const data = this.deviceEditForm.getRawValue()
    const deviceData: EditDeviceData = {}
    const config = this.device.config ? cloneDeep(this.device.config) : {}
    let configChanged = false
    if (data.name !== this.device.name) {
      deviceData.name = data.name!
    }
    if (
      data.identifier !== this.device.identifier ||
      data.deviceType?.value !== this.device.externalDeviceTypeId
    ) {
      deviceData.identifier = data.identifier!
      deviceData.deviceTypeId = data.deviceType!.value
      config.ident = deviceData.identifier
      configChanged = true
    }
    if (
      !!data.isVideo !== !!this.device.isVideo ||
      data.videoChannelsNumber !== this.device.videoChannelsNumber
    ) {
      deviceData.isVideo = !!data.isVideo
      deviceData.videoChannelsNumber = deviceData.isVideo ? data.videoChannelsNumber : null
    }

    if (!this.withPassword && !!config?.password) {
      delete config['password']
      configChanged = true
    }
    if (this.withPassword) {
      const password = data.password
      if (password && password !== config?.password) {
        config.password = password
        configChanged = true
      }
      if (!password && config?.password) {
        delete config['password']
        configChanged = true
      }
    }

    if (
      !!data.sim !== !!this.device.sim ||
      (data.sim && this.device.sim && data.sim !== this.device.sim) ||
      (!config.phone && this.configWithPhone) ||
      (config.phone && !this.configWithPhone)
    ) {
      deviceData.sim = data.sim || null
      if (config.phone && !this.configWithPhone) {
        delete config.phone
        configChanged = true
      }
      if (this.configWithPhone && deviceData.sim) {
        config.phone = deviceData.sim
        configChanged = true
      }
    }

    if (
      !!data.assignToFleet !== !!this.device.fleetId ||
      (data.assignToFleet && this.device.fleetId !== data.fleet?.id)
    ) {
      if (data.assignToFleet) {
        deviceData.fleetId = data.fleet!.id
      } else {
        deviceData.fleetId = null
      }
    }

    if (configChanged) {
      deviceData.config = config
    }

    // sensors
    if (
      data.odometerSource !== this.currentOdometerSource ||
      data.odometerParameter?.value !== this.currentOdometerParameter ||
      data.ignitionParameter?.value !== this.currentIgnitionParameter ||
      data.batteryParameter?.value !== this.currentBatteryParameter
    ) {
      deviceData.sensorsConfig = this.getSensorsConfigFromForm(data)
    }

    if (!Object.keys(deviceData).length) {
      result = true
    } else {
      let dialogResult = true
      if (this.device.fleetId && 'fleetId' in deviceData) {
        const labels = await this.getEditDialogLabels(
          data.name!,
          deviceData.fleetId ? this.device.fleet?.name : undefined,
          deviceData.fleetId ? data.fleet?.name : undefined,
        )
        dialogResult = await this.dialogFacade.confirm({
          ...labels,
          cancelButtonColor: 'var(--color-alert-500)',
        })
      }
      if (dialogResult) {
        try {
          const editResult = await this.devicesViewModel.editDevice(this.device.id!, deviceData)
          if (editResult.status === DeviceProcessingStatuses.done_completely) {
            result = true
          } else if (editResult.status === DeviceProcessingStatuses.done_partially) {
            result = false
          } else {
            this.errorMessageKey$.next(getApiErrorKey(ApiErrors.unknown))
          }
        } catch (e) {
          this.processErrorOnException(e)
        }
      }
    }

    if (result === true) {
      const commandIds = this.getCommandIdsToSet(data)
      const oldCommandIds = this.device.deviceCommands?.map((item) => item.commandId) || []
      let needToSetCommands = false
      if (
        commandIds.some((commandId) => !oldCommandIds.includes(commandId)) ||
        oldCommandIds.some((commandId) => !commandIds.includes(commandId))
      ) {
        needToSetCommands = true
      }
      if (needToSetCommands) {
        try {
          await this.devicesViewModel.setDeviceCommands(this.device.id!, commandIds)
          this.commandsListModel.refresh()
        } catch (e) {
          result = false
          const message = getMessageFromException(e)
          console.error(`Error to set device commands: ${message}`)
        }
      }
    }

    if (result !== null) {
      this.resetForm()
      this.submitEvent.emit(result)
    }
  }

  protected processErrorOnException(e: any) {
    const message = getMessageFromException(e)
    this.errorMessageKey$.next(
      checkExistApiError(message) ? getApiErrorKey(message) : getApiErrorKey(ApiErrors.unknown),
    )
    console.error(message)
  }

  protected async onSubmit() {
    this.deviceEditForm.updateValueAndValidity()
    if (!this.isValidForm) {
      this.deviceEditForm.markAllAsTouched()
      return
    }
    this.deviceEditForm.disable({ emitEvent: false })
    this.disableMode$.next(true)

    // check double
    const data = this.deviceEditForm.getRawValue()
    this.errorMessageKey$.next('')
    try {
      const params: DoubleDeviceCheckData = {
        protocolId: this.deviceTypeData!.protocolId,
        deviceTypeId: data.deviceType!.value,
        identifier: data.identifier!,
      }
      if (this.device.id) {
        params.deviceId = this.device.id
      }
      const double = await this.devicesViewModel.checkDoubleDevice(params)
      if (double) {
        this.errorMessageKey$.next(getApiErrorKey(ApiErrors.suchIdentifierAndProtocolAlreadyExists))
      }
    } catch (e) {
      console.error(getMessageFromException(e))
      this.errorMessageKey$.next(getApiErrorKey(ApiErrors.unknown))
    }
    if (this.errorMessageKey$.getValue()) {
      this.deviceEditForm.enable({ emitEvent: false })
      this.disableMode$.next(false)
      return
    }

    // check demo fleet
    if (data?.assignToFleet && data?.fleet?.id && data?.fleet?.isDemo) {
      try {
        const fleet = await this.devicesViewModel.checkFleetForAssignment(data.fleet.id)
        if (!fleet) {
          this.errorMessageKey$.next(getApiErrorKey(ApiErrors.unknown))
        }
      } catch (e) {
        this.processErrorOnException(e)
      }
    }
    if (this.errorMessageKey$.getValue()) {
      this.deviceEditForm.enable({ emitEvent: false })
      this.disableMode$.next(false)
      return
    }

    this.isLoading$.next(true)
    if (this.device.id) {
      await this.editDevice()
    } else {
      await this.createDevice()
    }
    this.deviceEditForm.enable({ emitEvent: false })
    this.isLoading$.next(false)
    this.disableMode$.next(false)
  }

  protected onCloseClick() {
    this.onCancel()
  }

  protected async getCommandsListToAssign(saveAssignedStatus = false) {
    if (this.deviceType?.value) {
      const enabledCommands = this.getCommandIdsToSet(this.deviceEditForm.getRawValue())
      this.isErrorLoadCommandList$.next(false)
      this.isLoadingCommandList$.next(true)
      this.deviceEditForm.setControl('assignCommandIdsStatuses', new FormGroup({}), {
        emitEvent: false,
      })
      try {
        const commands = await this.commandsListModel.getByDeviceTypeId(this.deviceType.value)
        if (commands?.length) {
          const firstCommand = commands[0]
          const commandDeviceType = firstCommand.externalDeviceTypeId
          if (commandDeviceType === this.deviceType?.value) {
            this.commandListToAssign$.next(commands)
            const assignCommandIdsStatusesFields: Record<string, any> = {}
            commands.forEach((command) => {
              assignCommandIdsStatusesFields[command.id] = new FormControl<boolean>(false)
            })
            this.deviceEditForm.setControl(
              'assignCommandIdsStatuses',
              new FormGroup(assignCommandIdsStatusesFields),
              {
                emitEvent: false,
              },
            )
            if (saveAssignedStatus && enabledCommands?.length) {
              enabledCommands.forEach((commandId) => {
                this.deviceEditForm
                  .get(['assignCommandIdsStatuses', commandId])
                  ?.setValue(true, { emitEvent: false })
              })
            }
          } else {
            this.commandListToAssign$.next([])
          }
        } else {
          this.commandListToAssign$.next([])
        }
      } catch (e) {
        this.isErrorLoadCommandList$.next(true)
      }
      this.isLoadingCommandList$.next(false)
    }
  }

  protected onEditCommandClicked(commandData: CommandMainData) {
    if (!this.disableMode$.getValue()) {
      this.commandsListModel.select({ ...cloneDeep(commandData), commandDevices: [] })
    }
  }

  protected onAddCommandClick() {
    if (!this.disableMode$.getValue()) {
      this.showAddCommandDialog$.next(true)
    }
  }

  public async addCommand(data: CommandFormData) {
    const result = await this.commandsListModel.createCommand(data)
    if (result) {
      await this.getCommandsListToAssign(true)
      this.showAddCommandDialog$.next(false)
      this.commandsListModel.refresh()
    }
  }

  protected async editCommand(data: CommandFormData) {
    const result = await this.commandsListModel.editCommand(data)
    if (result) {
      await this.getCommandsListToAssign(true)
      this.commandsListModel.refresh()
    }
  }

  protected onCancelAddCommand() {
    this.commandsListModel.cancelCreating()
    this.showAddCommandDialog$.next(false)
  }

  protected onCancelEditCommand() {
    this.commandsListModel.cancelEdit()
  }
}
