import { Component } from '@angular/core'
import { CommandDevicesListModel, CommandsListModel, DevicesListModel } from '../models'

@Component({
  selector: 'app-tsp-devices-page',
  templateUrl: 'devices-page.component.html',
  styleUrls: ['devices-page.component.scss'],
  providers: [DevicesListModel, CommandsListModel, CommandDevicesListModel],
})
export class DevicesPageComponent {}
