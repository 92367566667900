<div class="table-wrapper" [ngClass]="{ 'flex-grow-1': (data$ | async)?.length === 0 }">
  <app-data-grid
    [columns]="(columns$ | async) ?? []"
    [data]="(data$ | async) ?? []"
    [isLoading]="false"
    [noDataDescription]="'tsp-admin.billing.grid.no-data-description' | translate"
    [noDataIconTemplate]="gridEmptyStateIconTmpl"
    [noDataTitle]="'tsp-admin.billing.grid.no-data-title' | translate"
    [paginationEnabled]="false"
    [sortOrder]="undefined"
  ></app-data-grid>
</div>

<ng-template #gridEmptyStateIconTmpl>
  <i>
    <app-icon-pay-disable [color]="'var(--color-gray-900)'"></app-icon-pay-disable>
  </i>
</ng-template>
