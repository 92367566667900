import { Point } from 'typeorm'
import { Device } from './device'
import { Fleet } from './fleet'
import { MediaRequest } from './media-request'
import { Vehicle } from './vehicle'

export enum MediaFileType {
  Video,
  Photo,
}

export enum MediaFileStatus {
  Pending,
  Success,
}

export interface MediaFile {
  id: string
  name: string
  type: MediaFileType
  channelId: number
  metadata: Record<string, any>
  size: number
  previewFileSize: number
  filePath: string
  previewFilePath: string
  startDate: Date
  endDate: Date
  status: MediaFileStatus
  createdAt: Date
  fleetId: number
  tspId: number
  vehicleId: string
  deviceId: string
  externalDeviceId: number
  location: Point
  address: string
  requestId: number
  groupId: string
  deleted: boolean
  deletedAt: Date
  request: MediaRequest
  fleet: Fleet
  vehicle: Vehicle
  device: Device
}
