import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { loggedInGuard } from '@ti-platform/web/auth'
import { Action, Resource } from '@ti-platform/contracts'
import { NewPasswordComponent } from '@ti-platform/web/auth/components'
import {
  AccessDeniedPageComponent,
  DesignSystemComponent,
  NotFoundPageComponent,
} from '@ti-platform/web/ui-kit/layout/components'

import { allowOnEnv } from '@ti-platform/web/common/guards/allow-on-env'
import {
  FleetAccountsListComponent,
  FleetAccountsPageComponent,
  FleetAccountViewComponent,
} from '@ti-platform/tsp-admin/app/fleet'
import {
  CommandsListComponent,
  DevicesListComponent,
  DevicesPageComponent,
} from '@ti-platform/tsp-admin/app/devices/components'
import {
  TspAdminLayoutComponent,
  TspAdminSignInPageComponent,
} from '@ti-platform/tsp-admin/app/common'
import { TspAdminUsersPageComponent } from '@ti-platform/tsp-admin/app/users/components'
import { TspAdminBillingPageComponent } from '@ti-platform/tsp-admin/app/billing'
import { TspAdminRecyclingBinPageComponent } from '@ti-platform/tsp-admin/app/recycling-bin'
import { TspAdminWhiteLabelPageComponent } from '@ti-platform/tsp-admin/app/white-label/white-label-page.component'
import { checkAccess, smartRedirect } from '@ti-platform/web/common'

const routes: Routes = [
  {
    path: '',
    component: TspAdminLayoutComponent,
    canActivate: [loggedInGuard],
    children: [
      {
        path: 'users',
        component: TspAdminUsersPageComponent,
        canActivate: [checkAccess(Resource.User, Action.View)],
      },
      {
        path: 'accounts',
        component: FleetAccountsPageComponent,
        canActivate: [checkAccess(Resource.Fleet, Action.View)],
        children: [
          {
            path: '',
            component: FleetAccountsListComponent,
          },
          {
            path: ':id',
            component: FleetAccountViewComponent,
          },
          { path: '**', redirectTo: '' },
        ],
      },
      {
        path: 'devices',
        component: DevicesPageComponent,
        canActivate: [checkAccess(Resource.Device, Action.View)],
        children: [
          {
            path: '',
            component: DevicesListComponent,
          },
          {
            path: 'commands',
            component: CommandsListComponent,
          },
          { path: '**', redirectTo: '' },
        ],
      },
      {
        path: 'billing',
        canActivate: [checkAccess(Resource.Billing, Action.View)],
        component: TspAdminBillingPageComponent,
      },
      {
        path: 'white-label',
        canActivate: [checkAccess(Resource.WhiteLabel, Action.View)],
        component: TspAdminWhiteLabelPageComponent,
      },
      { path: 'recycling-bin', component: TspAdminRecyclingBinPageComponent },
      {
        path: '',
        canActivate: [
          smartRedirect(
            {
              users: [Resource.User, Action.View],
              accounts: [Resource.Fleet, Action.View],
              devices: [Resource.Device, Action.View],
              billing: [Resource.Billing, Action.View],
              'white-label': [Resource.WhiteLabel, Action.View],
            },
            'accounts',
          ),
        ],
        pathMatch: 'full',
        component: NotFoundPageComponent,
      },
    ],
  },
  { path: 'sign-in', component: TspAdminSignInPageComponent },
  { path: 'change-password', component: NewPasswordComponent },
  { path: 'create-password/:secret', component: NewPasswordComponent },
  { path: 'reset-password/:secret', component: NewPasswordComponent },

  {
    path: '___design-system',
    component: DesignSystemComponent,
    canActivate: [loggedInGuard],
    canMatch: [allowOnEnv(['local', 'dev'])],
  },

  { path: 'access-denied', component: AccessDeniedPageComponent },
  { path: '**', redirectTo: '' },
]

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
