import { Component, inject } from '@angular/core'
import { Memoize } from '@ti-platform/web/common'
import { combineLatest, from, map, Observable } from 'rxjs'
import { DataGridColumn, DataGridColumnType } from '@ti-platform/web/ui-kit/layout/components'
import { LanguageService } from '@ti-platform/web/ui-kit/i18n'

@Component({
  selector: 'app-tsp-admin-billing-list',
  templateUrl: 'billing-grid.component.html',
  styleUrls: ['billing-grid.component.scss'],
})
export class TspAdminBillingGridComponent {
  protected readonly languageService = inject(LanguageService)

  @Memoize()
  protected get data$(): Observable<BillingGridData[]> {
    return from([])
  }

  @Memoize()
  protected get columns$(): Observable<DataGridColumn[]> {
    return combineLatest([
      this.languageService.translate$('tsp-admin.billing.grid.invoice'),
      this.languageService.translate$('tsp-admin.billing.grid.amount'),
      this.languageService.translate$('tsp-admin.billing.grid.billingPeriod'),
      this.languageService.translate$('tsp-admin.billing.grid.status'),
    ]).pipe(
      map((labels) => {
        const [invoice, amount, billingPeriod, status] = labels
        return [
          {
            field: 'invoice',
            label: invoice,
            type: DataGridColumnType.User,
            sortable: true,
          },
          {
            field: 'amount',
            label: amount,
            type: DataGridColumnType.Text,
            sortable: true,
          },
          {
            field: 'billingPeriod',
            label: billingPeriod,
            type: DataGridColumnType.Text,
            sortable: true,
          },
          {
            field: 'status',
            label: status,
            type: DataGridColumnType.Text,
            sortable: true,
          },
        ]
      }),
    )
  }
}

export interface BillingGridData {}
