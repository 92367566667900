@if (state.selectedItem$ | async; as fleet) {
  <header class="tsp-admin-header">
    <section class="heading-group flex">
      <p-button
        (click)="goBack()"
        class="button-back show-on--mobile h--48 flex"
        icon="pi"
        [severity]="'secondary'"
      >
        <app-icon-arrow [height]="24" [width]="24" direction="left"></app-icon-arrow>
      </p-button>
      <h1 class="text-32 fw-600">
        {{ fleet.name }}
      </h1>
      @if (!fleet.isActive) {
        <p-tag [rounded]="true" class="alert fw-600 r-6 p-4-6">
          {{ 'tsp-admin.fleets.grid.suspended' | translate }}
        </p-tag>
      }
      @if (fleet.isDemo) {
        <p-tag [rounded]="true" class="warning fw-600 r-6 p-4-6">
          {{ 'tsp-admin.fleets.grid.demo' | translate }}
        </p-tag>
      }
    </section>
    <section class="controls">
      @if (fleet.isActive) {
        <p-button
          class="h--40 p--18 text-14 fw-600 red"
          [appCheckAccess]="[Resource.Fleet, Action.Suspend]"
          (click)="suspendFleet(fleet)"
        >
          <i class="pi pi-pause"> </i>
          {{ 'tsp-admin.fleets.page.suspend-btn' | translate }}
        </p-button>
      } @else {
        <p-button
          class="h--40 p--18 text-14 fw-600 red"
          [severity]="'secondary'"
          [appCheckAccess]="[Resource.Fleet, Action.Suspend]"
          (click)="activateFleet(fleet)"
        >
          <i class="pi pi-lock-open"></i>
          {{ 'tsp-admin.fleets.page.activate-btn' | translate }}
        </p-button>
      }

      <p-button
        class="h--40 p--18 text-14 fw-600"
        [severity]="'secondary'"
        [appCheckAccess]="[Resource.Fleet, Action.Update]"
        (click)="showEditForm$.next(true)"
      >
        <i class="pi pi-pencil"></i>
        {{ 'tsp-admin.fleets.page.edit-btn' | translate }}
      </p-button>

      <div class="controls-spacer"></div>

      <p-button
        class="h--40 p--18 text-14 fw-600 red"
        [appCheckAccess]="[Resource.Fleet, Action.Delete]"
        (click)="deleteFleet(fleet)"
      >
        <i class="pi pi-trash"></i>
        {{ 'tsp-admin.fleets.page.delete-btn' | translate }}
      </p-button>
    </section>
  </header>

  <section class="content-wrapper">
    <div class="left-section">
      <header class="content-header flex">
        <p-tabView class="flex">
          <p-tabPanel
            class="max-height"
            [disabled]="(accessControl.check$(Resource.Device, Action.View) | async) === false"
          >
            <ng-template pTemplate="header">
              <div class="navigation-item text-14 fw-600">
                {{ 'tsp-admin.fleets.tabs.devices' | translate }}
              </div>
            </ng-template>
            <app-tsp-devices-list
              (devicesChanged)="refreshSelectedFleet(fleet.id)"
              [displayColumns]="devicesDisplayColumns"
              [headerClass]="'p-24-0'"
              [showDevicesCount]="false"
              [showManageCommands]="false"
            >
            </app-tsp-devices-list>
          </p-tabPanel>

          <p-tabPanel
            class="max-height"
            [disabled]="(accessControl.check$(Resource.User, Action.View) | async) === false"
          >
            <ng-template pTemplate="header">
              <div class="navigation-item text-14 fw-600">
                {{ 'tsp-admin.fleets.tabs.users' | translate }}
              </div>
            </ng-template>
            <app-users-grid
              [displayColumns]="usersDisplayColumns"
              (loginAs)="usersModel.loginAsFleetUser($event)"
            ></app-users-grid>
          </p-tabPanel>

          <p-tabPanel
            class="max-height"
            [disabled]="(accessControl.check$(Resource.Billing, Action.View) | async) === false"
          >
            <ng-template pTemplate="header">
              <div class="navigation-item text-14 fw-600">
                {{ 'tsp-admin.fleets.tabs.billing' | translate }}
              </div>
            </ng-template>
            <app-tsp-admin-fleet-billing></app-tsp-admin-fleet-billing>
          </p-tabPanel>
        </p-tabView>
      </header>
    </div>
    <div class="right-section">
      <h3 class="text-24 fw-600 mb-24">{{ 'tsp-admin.fleets.page.about' | translate }}</h3>

      <!-- <div class="icon-description-card mb-12">
        <section class="description">
          <div class="title text-18 fw-600 mb-6">$20,000.00</div>
          <div class="sub-title text-14 fw-00">
            {{ 'tsp-admin.fleets.page.lifetime-value' | translate }}
          </div>
        </section>
        <div class="avatar avatar--48 flex">
          <i>
            <app-icon-dollar [color]="'var(--icon-color)'"></app-icon-dollar>
          </i>
        </div>
      </div> -->

      <div class="icon-description-card mb-12">
        <section class="description">
          <div class="title text-18 fw-600 mb-6">
            {{ (pricingPlansProvider.pricingPlansLabels$ | async)?.[fleet.pricingPlanId] || '-' }}
          </div>
          <div class="sub-title text-14 fw-00">
            {{ 'tsp-admin.fleets.form.input-label.pricing-plan' | translate }}
          </div>
        </section>
        <div class="avatar avatar--48 flex">
          <i>
            <app-icon-dollar [color]="'var(--icon-color)'"></app-icon-dollar>
          </i>
        </div>
      </div>

      <div class="icon-description-card">
        <section class="description">
          <div class="title text-18 fw-600 mb-6">{{ formatFleetDevicesCount(fleet) }}</div>
          <div class="sub-title text-14 fw-00">
            {{ 'tsp-admin.fleets.page.devices' | translate }}
          </div>
        </section>
        <div class="avatar avatar--48 flex">
          <i>
            <app-icon-device [color]="'var(--icon-color)'"></app-icon-device>
          </i>
        </div>
      </div>

      <div class="horizontal-spacer--24"></div>

      <div class="icon-data-row mb-16">
        <i>
          <app-icon-date
            [color]="'var(--color-gray-500)'"
            [height]="24"
            [width]="24"
          ></app-icon-date>
        </i>
        <section class="data">
          <div class="description text-14 fw-500">
            {{ 'tsp-admin.fleets.fields.created-at' | translate }}
          </div>
          <div class="value text-14 fw-500">
            {{ fleet.createdAt | date }}
          </div>
        </section>
      </div>

      <div class="icon-data-row mb-16">
        <i>
          <app-icon-user
            [color]="'var(--color-gray-500)'"
            [height]="24"
            [width]="24"
          ></app-icon-user>
        </i>
        <section class="data">
          <div class="description text-14 fw-500">
            {{ 'tsp-admin.fleets.fields.contact-name' | translate }}
          </div>
          <div class="value text-14 fw-500">
            {{ fleet.contactName }}
          </div>
        </section>
      </div>

      <div class="icon-data-row mb-16">
        <i>
          <app-icon-email
            [color]="'var(--color-gray-500)'"
            [height]="24"
            [width]="24"
          ></app-icon-email>
        </i>
        <section class="data">
          <div class="description text-14 fw-500">
            {{ 'tsp-admin.fleets.fields.email' | translate }}
          </div>
          <div class="value text-14 fw-500">
            {{ fleet.email }}
          </div>
        </section>
      </div>

      @if (fleet.phone) {
        <div class="icon-data-row mb-16">
          <i class="pi pi-phone"></i>
          <section class="data">
            <div class="description text-14 fw-500">
              {{ 'tsp-admin.fleets.fields.phone' | translate }}
            </div>
            <div class="value text-14 fw-500">
              {{ fleet.phone | phone }}
            </div>
          </section>
        </div>
      }

      <div class="icon-data-row mb-16">
        <i class="pi pi-map-marker"></i>
        <section class="data">
          <div class="description text-14 fw-500">
            {{ 'tsp-admin.fleets.page.address' | translate }}
          </div>
          <div class="value text-14 fw-500">
            {{ formattedAddress$ | async }}
          </div>
        </section>
      </div>

      <div class="horizontal-spacer--24"></div>

      <ng-container *ngIf="fleet.measurementSystem as measurementSystem">
        <div class="icon-data-row mb-16">
          <i>
            <app-icon-distance
              [color]="'var(--color-gray-500)'"
              [height]="24"
              [width]="24"
            ></app-icon-distance>
          </i>
          <section class="data">
            <div class="description text-14 fw-500">
              {{ 'tsp-admin.fleets.fields.measurement-distance' | translate }}
            </div>
            <div class="value text-14 fw-500">
              {{
                (measurementSystem?.distance === 0
                    ? 'measurement-system.kilometers'
                    : 'measurement-system.miles'
                ) | translate
              }}
            </div>
          </section>
        </div>

        <div class="icon-data-row mb-16">
          <i>
            <app-icon-water
              [color]="'var(--color-gray-500)'"
              [height]="24"
              [width]="24"
            ></app-icon-water>
          </i>
          <section class="data">
            <div class="description text-14 fw-500">
              {{ 'tsp-admin.fleets.fields.measurement-volume' | translate }}
            </div>
            <div class="value text-14 fw-500">
              {{
                (measurementSystem?.volume === 0
                    ? 'measurement-system.liters'
                    : 'measurement-system.gallons'
                ) | translate
              }}
            </div>
          </section>
        </div>

        <div class="icon-data-row mb-16">
          <i>
            <app-icon-fuel
              [color]="'var(--color-gray-500)'"
              [height]="24"
              [width]="24"
            ></app-icon-fuel>
          </i>
          <section class="data">
            <div class="description text-14 fw-500">
              {{ 'tsp-admin.fleets.fields.measurement-consumption' | translate }}
            </div>
            <div class="value text-14 fw-500">
              {{ consumptionLabels[measurementSystem?.consumption ?? 1] | translate }}
            </div>
          </section>
        </div>

        <div class="icon-data-row mb-16">
          <i>
            <app-icon-temperature
              [color]="'var(--color-gray-500)'"
              [height]="24"
              [width]="24"
            ></app-icon-temperature>
          </i>
          <section class="data">
            <div class="description text-14 fw-500">
              {{ 'tsp-admin.fleets.fields.measurement-temperature' | translate }}
            </div>
            <div class="value text-14 fw-500">
              {{
                (measurementSystem?.temperature === 0
                    ? 'measurement-system.celsius'
                    : 'measurement-system.fahrenheit'
                ) | translate
              }}
            </div>
          </section>
        </div>
      </ng-container>
    </div>
  </section>

  @if (showEditForm$ | async) {
    <app-tsp-admin-edit-fleet-dialog
      [opened]="true"
      [fleet]="fleet"
      [processing]="!!(updatingFleet$ | async)"
      [errorMessage]="(state.errorMessage$ | async) || ''"
      (update)="updateFleet($event)"
      (cancel)="showEditForm$.next(false)"
    ></app-tsp-admin-edit-fleet-dialog>
  }
}
