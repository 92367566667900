import { platformBrowserDynamic } from '@angular/platform-browser-dynamic'

import { AppModule } from './app/app.module'
import { Amplify } from 'aws-amplify'
import * as Sentry from '@sentry/angular'
import environment from '@ti-platform/tsp-admin/env'

if (window && window?.location?.hostname !== 'localhost' && environment.sentry.enabled) {
  Sentry.init({
    dsn: environment.sentry.dsn,
    enabled: true,
    ignoreErrors: ['UserUnAuthenticatedException'],
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
      Sentry.replayCanvasIntegration(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: environment.sentry.tracePropagationTargets,
    // Session Replay
    replaysSessionSampleRate: 1.0, // This sets the sample rate at 100%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  })
}

Amplify.configure({
  Auth: {
    Cognito: {
      userPoolId: environment.amplify.userPoolId,
      allowGuestAccess: false,
      userPoolClientId: environment.amplify.userPoolClientId,
      identityPoolId: environment.amplify.identityPoolId,
    },
  },
})

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .then(() => {
    const loadingElement = document.getElementById('loading-animation')
    if (loadingElement) {
      loadingElement.parentNode?.removeChild(loadingElement)
    }
  })
  .catch((err) => console.error(err))
