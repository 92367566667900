<p-dialog
  [(visible)]="opened"
  [modal]="true"
  class="dialog-primary extra-large headless"
  closable="false"
>
  <ng-template pTemplate="header"></ng-template>
  <div class="upload-device-dialog-content">
    <div class="header flex">
      <div class="header-title">{{ 'tsp-admin.devices.add.load-list.title' | translate }}</div>

      <div class="header-icon-wrapper">
        <i class="pi pi-times header-icon clickable" (click)="onCloseClick()"></i>
      </div>
    </div>

    <div
      class="body"
      [class]="{
        'center-content':
          (currentStep$ | async) === UploadSteps.fileUploading ||
          (currentStep$ | async) === UploadSteps.dataValidation ||
          ((currentStep$ | async) === UploadSteps.dataValidationResult &&
            (unvalidatedData$ | async)?.length === 0) ||
          (currentStep$ | async) === UploadSteps.addingDevices ||
          (currentStep$ | async) === UploadSteps.addingResult,
      }"
    >
      @if (
        (currentStep$ | async) === UploadSteps.selectDeviceType ||
        (currentStep$ | async) === UploadSteps.selectFile ||
        (currentStep$ | async) === UploadSteps.fileValidationError
      ) {
        <!-- file requirements info -->
        <p-accordion
          class="file-requirements chevron-right expand-chevron-left hover-disabled borderless inherit-content-color"
          [(activeIndex)]="requirementsAccordionIndex"
          [class]="{ expanded: requirementsAccordionIndex === 0 }"
        >
          <p-accordionTab>
            <ng-template pTemplate="header">
              <div class="file-requirements__header">
                <app-icon-info
                  [color]="'var(--blue-500)'"
                  [width]="16"
                  [height]="16"
                  class="mr-8"
                ></app-icon-info>
                <span class="file-requirements__title text-13 fw-400">
                  {{ 'tsp-admin.devices.add.load-list.file-requirements' | translate }}:
                </span>
              </div>
            </ng-template>
            <div class="file-requirements__list">
              <p>- {{ 'tsp-admin.devices.add.load-list.requirements.format' | translate }}</p>
              <p>- {{ 'tsp-admin.devices.add.load-list.requirements.size' | translate }}</p>
              <p>- {{ 'tsp-admin.devices.add.load-list.requirements.rows' | translate }}</p>
              <p>- {{ 'tsp-admin.devices.add.load-list.requirements.columns' | translate }}</p>
            </div>
          </p-accordionTab>
        </p-accordion>

        <!-- device type select -->
        <div class="device-type-select">
          <div class="mb-8 text-14 fw-500 label">
            {{ 'tsp-admin.devices.add.load-list.choose-brand-and-model' | translate }}:
          </div>

          <app-autocomplete-select
            (completeMethod)="onFilterTypesData($event)"
            [completeOnFocus]="true"
            [forceSelection]="true"
            [suggestions]="filteredTypesData"
            [value]="deviceType"
            (valueChange)="onChangeDeviceType($event)"
            optionLabel="label"
            placeholder="{{ 'tsp-admin.devices.add.load-list.select-device-type' | translate }}"
          ></app-autocomplete-select>
        </div>
      }

      <!-- file selection and selection after error -->
      @if (
        (currentStep$ | async) === UploadSteps.selectFile ||
        (currentStep$ | async) === UploadSteps.fileValidationError
      ) {
        <div class="file-selection-zone" (dropzoneDropped)="loadFile($event[0])" dropzone>
          @if ((currentStep$ | async) === UploadSteps.selectFile) {
            <div class="upload-file-zone">
              <div class="upload-file-zone__inner">
                <app-icon-upload></app-icon-upload>
              </div>
            </div>
            <div class="file-select-summary text-14 fw-500">
              {{ 'tsp-admin.devices.add.load-list.drag-and-drop' | translate }}
            </div>
            <div class="file-select-description text-14 fw-400">
              {{ 'tsp-admin.devices.add.load-list.file-select-sescription' | translate }}
            </div>
          }
          @if ((currentStep$ | async) === UploadSteps.fileValidationError) {
            <div class="upload-file-warning">
              <app-icon-warning></app-icon-warning>
            </div>
            <div class="file-select-summary text-14 fw-500">
              {{ fileSelectErrorSummary | translate }}
            </div>
            <div class="file-select-description text-14 fw-400">
              {{ fileSelectErrorDescription | translate }}
            </div>
          }
          <div class="manual-upload flex text-group">
            <span class="text-14 fw-600">
              <input
                (change)="onFileSelected($event)"
                accept="text/csv"
                hidden
                id="uploadFile"
                type="file"
              />
              <label pRipple class="clickable manual-upload__label" for="uploadFile">
                {{ 'tsp-admin.devices.add.load-list.select-file' | translate }}
              </label>
            </span>
          </div>
        </div>
      }

      @if ((currentStep$ | async) === UploadSteps.fileUploading) {
        <div class="summary-text text-14 fw-500">{{ 'common.loading' | translate }}...</div>
      } @else if ((currentStep$ | async) === UploadSteps.uploadConfiguration) {
        <div class="summary-text text-14 fw-500">
          {{ 'tsp-admin.devices.add.load-list.file-uploaded' | translate }}
        </div>
      } @else if ((currentStep$ | async) === UploadSteps.dataValidation) {
        <div class="summary-text text-14 fw-500">
          {{ 'tsp-admin.devices.add.load-list.validating-data' | translate }}...
        </div>
      } @else if ((currentStep$ | async) === UploadSteps.addingDevices) {
        <div class="summary-text text-14 fw-500">
          {{ 'tsp-admin.devices.add.load-list.adding-devices' | translate }}
        </div>
        <div class="summary-text text-14 fw-500 mt-16">
          {{ 'tsp-admin.devices.add.load-list.take-time' | translate }}...
        </div>
      } @else if ((currentStep$ | async) === UploadSteps.addingResult) {
        <div class="summary-text text-20 fw-500">
          {{ 'tsp-admin.devices.add.load-list.completed' | translate }}
        </div>
        <div class="summary-text text-14 fw-500 mt-8">
          {{ 'tsp-admin.devices.add.load-list.adding-completed' | translate }}
        </div>
      }

      <!-- file data -->
      @if (
        (currentStep$ | async) === UploadSteps.fileUploading ||
        (currentStep$ | async) === UploadSteps.uploadConfiguration
      ) {
        <div class="file-description">
          <div class="file-description__content">
            <app-icon-csv-file-colorful class="mr-8"></app-icon-csv-file-colorful>
            <div class="file-data">
              <div class="file-name text-14 fw-500">{{ fileName }}</div>
              <div class="text-12 fw-500">
                <span class="file-format mr-8">CSV</span>
                <span class="file-size">{{ formattedFileSize }}</span>
              </div>
            </div>
            @if ((currentStep$ | async) === UploadSteps.uploadConfiguration) {
              <app-icon-check class="check-icon"></app-icon-check>
            }
          </div>
          @if ((currentStep$ | async) === UploadSteps.fileUploading) {
            <p-progressBar [value]="(fileUploadProgress$ | async) || 0" styleClass="file-progress">
              <ng-template pTemplate="content"> </ng-template>
            </p-progressBar>
          } @else {
            <p-progressBar [value]="100" styleClass="file-progress">
              <ng-template pTemplate="content"> </ng-template>
            </p-progressBar>
          }
        </div>
      }

      @if ((currentStep$ | async) === UploadSteps.uploadConfiguration) {
        <div class="file-configuration">
          <div class="file-configuration__description text-14 fw-400">
            {{ 'tsp-admin.devices.add.load-list.configuration-description' | translate }}:
          </div>
          <div class="file-configuration__columns">
            <!-- name -->
            <div class="column-label text-14 fw-500">
              {{ 'tsp-admin.devices.grid.device-name' | translate }}:
            </div>
            <ng-container
              [ngTemplateOutlet]="columnSelector"
              [ngTemplateOutletContext]="{ paramName: ColumnType.Name }"
            ></ng-container>
            <!-- imei -->
            <div class="column-label text-14 fw-500">
              {{ 'tsp-admin.devices.grid.imei-serial' | translate }}:
            </div>
            <ng-container
              [ngTemplateOutlet]="columnSelector"
              [ngTemplateOutletContext]="{ paramName: ColumnType.Ident }"
            ></ng-container>
            <!-- password -->
            @if (withPassword) {
              <div class="column-label text-14 fw-500">
                <span>
                  {{ 'tsp-admin.devices.add.load-list.password' | translate }}
                </span>
                <span class="gray">
                  &nbsp;({{ 'tsp-admin.devices.add.load-list.optional' | translate }}):
                </span>
              </div>
              <ng-container
                [ngTemplateOutlet]="columnSelector"
                [ngTemplateOutletContext]="{ paramName: ColumnType.Password }"
              ></ng-container>
            }
            <!-- phone -->
            <div class="column-label text-14 fw-500">
              <span>
                {{ 'tsp-admin.devices.add.load-list.phone-number' | translate }}
              </span>
              <span class="gray">
                &nbsp;({{ 'tsp-admin.devices.add.load-list.optional' | translate }}):
              </span>
            </div>
            <ng-container
              [ngTemplateOutlet]="columnSelector"
              [ngTemplateOutletContext]="{ paramName: ColumnType.Phone }"
            ></ng-container>
          </div>
          @if (((fileData$ | async) || []).length > 1) {
            <div class="horizontal-spacer--12"></div>
            <div class="file-configuration__first_row text-14 fw-400">
              <p-checkbox
                [(ngModel)]="firstRowIsTitle"
                [binary]="true"
                inputId="firstRowIsTitle"
                class="mr-8"
              />
              <label for="firstRowIsTitle">
                {{ 'tsp-admin.devices.add.load-list.first-row-is-title' | translate }}
              </label>
            </div>
          }
        </div>
      }

      @if ((currentStep$ | async) === UploadSteps.dataValidation) {
        <p-progressBar [value]="(dataValidationProgress$ | async) || 0" styleClass="main-progress">
          <ng-template pTemplate="content"> </ng-template>
        </p-progressBar>
      } @else if ((currentStep$ | async) === UploadSteps.addingDevices) {
        <p-progressBar [value]="(addingDevicesProgress$ | async) || 0" styleClass="main-progress">
          <ng-template pTemplate="content"> </ng-template>
        </p-progressBar>
      } @else if ((currentStep$ | async) === UploadSteps.addingResult) {
        <p-progressBar [value]="100" styleClass="main-progress">
          <ng-template pTemplate="content"> </ng-template>
        </p-progressBar>
      }

      @if ((currentStep$ | async) === UploadSteps.dataValidationResult) {
        <div class="device-icon-field">
          <app-icon-device></app-icon-device>
        </div>
        <div class="summary-text text-14 fw-500 mt-16">
          <span class="fw-900">
            {{
              'tsp-admin.devices.add.load-list.records-result'
                | translate
                  : {
                      validatedNumber: (validatedData$ | async)?.length || 0,
                    }
            }}
          </span>
          {{ 'tsp-admin.devices.add.load-list.validated-description' | translate }}
        </div>
        <div class="summary-text text-14 fw-500 mt-16">
          {{ 'tsp-admin.devices.add.load-list.proceed-adding' | translate }}
        </div>
        @if ((unvalidatedData$ | async)?.length) {
          <div class="validation-result text-13 fw-400">
            <div class="validation-result__description">
              <app-icon-warning-circle class="mr-8 icon"></app-icon-warning-circle>
              <div class="text">
                <span>
                  {{
                    'tsp-admin.devices.add.load-list.validation-result-part-1'
                      | translate
                        : {
                            unvalidatedNumber: (unvalidatedData$ | async)?.length || 0,
                          }
                  }}
                </span>
                <span class="fw-900">{{ fileName }}</span>
                <span>
                  {{ 'tsp-admin.devices.add.load-list.validation-result-part-2' | translate }}:
                </span>
              </div>
            </div>
            <div class="validation-result__rows-data scroll-wrapper scrollbar-gray">
              <ul>
                @for (element of unvalidatedData$ | async; track element; let i = $index) {
                  <li>
                    <span class="mr-4">{{
                      'tsp-admin.devices.add.load-list.row-number'
                        | translate: { number: element.rowIndex + 1 }
                    }}</span>
                    <span>({{ element.validationError }})</span>
                  </li>
                }
              </ul>
            </div>
          </div>
        }
      }

      @if (
        (currentStep$ | async) === UploadSteps.addingResult && (failedAddedIdents$ | async)?.length
      ) {
        <p-accordion
          class="not-added-devices chevron-right expand-chevron-left borderless hover-disabled inherit-content-color"
          [(activeIndex)]="notAddedDeviceInfoAccordionIndex"
          [class]="{ expanded: notAddedDeviceInfoAccordionIndex === 0 }"
        >
          <p-accordionTab>
            <ng-template pTemplate="header">
              <div class="not-added-devices__header">
                <app-icon-warning-circle
                  [color]="'var(--color-secondary-500)'"
                  [width]="16"
                  [height]="16"
                  class="mr-8"
                ></app-icon-warning-circle>
                <span class="not-added-devices__title text-13 fw-400">
                  {{
                    'tsp-admin.devices.add.load-list.devices-not-added'
                      | translate: { number: (failedAddedIdents$ | async)?.length || 0 }
                  }}:
                </span>
              </div>
            </ng-template>
            <div class="not-added-devices__list scroll-wrapper scrollbar-gray">
              <ul>
                @for (element of failedAddedIdents$ | async; track element; let i = $index) {
                  <li>
                    <span class="mr-4">
                      {{ 'tsp-admin.devices.add.load-list.imei' | translate }}:
                    </span>
                    <span>{{ element }}</span>
                  </li>
                }
              </ul>
            </div>
          </p-accordionTab>
        </p-accordion>
      }
    </div>
    <div
      class="footer"
      [class]="{ 'single-button': ((currentStep$ | async) || 0) >= UploadSteps.addingDevices }"
    >
      @if (((currentStep$ | async) || 0) < UploadSteps.addingResult) {
        <p-button
          (click)="onCancel()"
          [severity]="'secondary'"
          class="cancel text-14 fw-600 w50 p--8 h--40 footer-button"
        >
          {{ 'button.cancel' | translate }}
        </p-button>
      }

      @if (((currentStep$ | async) || 0) < UploadSteps.addingDevices) {
        <p-button
          (click)="onContinue()"
          [disabled]="!canContinue"
          class="submit text-14 fw-600 w50 h--40 footer-button"
          type="submit"
        >
          @if ((currentStep$ | async) === UploadSteps.dataValidationResult) {
            {{
              'tsp-admin.devices.add.load-list.add-button'
                | translate
                  : {
                      val: (validatedData$ | async)?.length || 0,
                    }
            }}
          } @else {
            {{ 'button.continue' | translate }}
          }
        </p-button>
      }

      @if ((currentStep$ | async) === UploadSteps.addingResult) {
        <p-button
          (click)="onSubmit()"
          [loading]="(isLoading$ | async) || false"
          class="submit text-14 fw-600 w50 h--40 footer-button"
          type="submit"
        >
          {{ 'common.ok' | translate }}
        </p-button>
      }
    </div>
  </div>
</p-dialog>

<ng-template #columnSelector let-paramName="paramName">
  <p-dropdown
    class="h--40"
    [options]="(columnOptions$ | async) || []"
    appendTo="body"
    optionLabel="label"
    optionValue="value"
    [ngModel]="(columnConfiguration$ | async)?.[paramName]"
    (ngModelChange)="onConfigurationColumnChange(paramName, $event)"
    placeholder="{{ 'tsp-admin.devices.add.load-list.select-column' | translate }}"
  >
    <ng-template pTemplate="selectedItem">
      <div class="text-14" *ngIf="(columnConfiguration$ | async)?.[paramName] !== null">
        <div class="seleted-item">
          <span class="fw-700">
            {{ 'tsp-admin.devices.add.load-list.column' | translate }}
            {{ ColumnsLabels[(columnConfiguration$ | async)?.[paramName]] }}:
          </span>
          {{ ((columnOptions$ | async) || [])[(columnConfiguration$ | async)?.[paramName]].label }}
        </div>
      </div>
    </ng-template>
    <ng-template let-param pTemplate="item">
      <div class="option-item text-14">
        <div>
          <span class="mr-8 fw-700">
            {{ 'tsp-admin.devices.add.load-list.column' | translate }}
            {{ ColumnsLabels[param.value] }}:
          </span>
          {{ param.label }}
        </div>
      </div>
    </ng-template>
  </p-dropdown>
</ng-template>
