@if (header) {
  <div class="header flex">
    <div class="header-title">{{ header }}</div>

    <div class="header-icon-wrapper">
      <i
        class="pi pi-times header-icon clickable"
        [class]="{ disabled: !!(disableMode$ | async) }"
        (click)="onCloseClick()"
      ></i>
    </div>
  </div>
}

<form [formGroup]="deviceEditForm" class="p-fluid medium device-form">
  <p-tabView [(activeIndex)]="currentTab" class="device-form-tabs">
    <p-tabPanel>
      <ng-template pTemplate="header">
        <div class="navigation-item text-14 fw-600">
          {{ 'tsp-admin.devices.tabs.configuration' | translate }}
        </div>
      </ng-template>
      <section class="inputs-section">
        <div class="label mb-8 text-14 fw-500">
          {{ 'tsp-admin.devices.device-name' | translate }}<span class="required">*</span>
        </div>
        <input
          class="mb-16 h--40"
          formControlName="name"
          pInputText
          placeholder="{{ 'tsp-admin.devices.enter-name' | translate }}"
          type="text"
        />

        <div class="label mb-8 text-14 fw-500">
          {{ 'tsp-admin.devices.device-brand-and-model' | translate }}
          <span class="required">*</span>
        </div>

        <app-autocomplete-select
          (completeMethod)="onFilterTypesData($event)"
          [completeOnFocus]="true"
          [forceSelection]="true"
          [suggestions]="filteredTypesData"
          formControlName="deviceType"
          optionLabel="label"
          placeholder="{{ 'tsp-admin.devices.select-brand-and-model' | translate }}"
          className="mb-16"
        ></app-autocomplete-select>

        <div class="field checkbox mb-16">
          <p-checkbox [binary]="true" formControlName="isVideo"></p-checkbox>
          <span class="checkbox-label text-14">{{
            'tsp-admin.devices.video-device' | translate
          }}</span>
        </div>

        @if (deviceEditForm.value.isVideo) {
          <div class="label mb-8 text-14 fw-500">
            {{ 'tsp-admin.devices.video-channels' | translate }}<span class="required">*</span>
          </div>

          <p-dropdown
            class="h--40 mb-16"
            [options]="videoChannels"
            appendTo="body"
            optionLabel="name"
            optionValue="value"
            formControlName="videoChannelsNumber"
            placeholder="{{ 'tsp-admin.devices.select-number-of-channels' | translate }}"
          >
            <ng-template let-param pTemplate="item">
              <div class="option-item text-14">
                <div>{{ param.name }}</div>
              </div>
            </ng-template>
          </p-dropdown>
        }

        <div class="field-double mb-16">
          <div class="col">
            <div class="label mb-8 text-14 fw-500">
              {{ 'tsp-admin.devices.imei-or-serial' | translate }}<span class="required">*</span>
            </div>
            <input
              [pTooltip]="identifierTooltipKey ? (identifierTooltipKey | translate) : ''"
              [tooltipPosition]="'bottom'"
              class="h--40"
              formControlName="identifier"
              pInputText
              placeholder="{{ 'tsp-admin.devices.enter-number' | translate }}"
              type="text"
            />
          </div>

          <div class="col">
            <div class="label mb-8 text-14 fw-500">
              {{ 'tsp-admin.devices.sim-card-phone-iccid' | translate }}
            </div>
            <input
              [pTooltip]="'tsp-admin.devices.sim-check' | translate"
              [tooltipPosition]="'bottom'"
              class="h--40"
              formControlName="sim"
              pInputText
              placeholder="{{ 'tsp-admin.devices.enter-number' | translate }}"
              type="text"
            />
          </div>
        </div>

        @if (withPassword) {
          <div class="label mb-8 text-14 fw-500">
            {{ 'tsp-admin.devices.device-password' | translate }}
          </div>
          <div class="field mb-16">
            <app-password-input formControlName="password" [height]="40"></app-password-input>
          </div>
        }

        <div class="label mb-8 text-14 fw-500">
          {{ 'tsp-admin.devices.fleet-account' | translate }}
        </div>
        <div class="field checkbox" [class]="{ 'mb-16': deviceEditForm.value.assignToFleet }">
          <p-checkbox [binary]="true" formControlName="assignToFleet"></p-checkbox>
          <span class="checkbox-label text-14">{{
            'tsp-admin.devices.assign-to-fleet-account' | translate
          }}</span>
        </div>

        @if (deviceEditForm.value.assignToFleet) {
          <app-autocomplete-select
            (completeMethod)="onFilterFleet($event)"
            [completeOnFocus]="true"
            [forceSelection]="true"
            [suggestions]="filteredFleets"
            formControlName="fleet"
            optionLabel="name"
            placeholder="{{ 'tsp-admin.devices.select-fleet' | translate }}"
          ></app-autocomplete-select>
        }
      </section>
    </p-tabPanel>
    <p-tabPanel [disabled]="!deviceType">
      <ng-template pTemplate="header">
        <div class="navigation-item text-14 fw-600">
          {{ 'tsp-admin.devices.tabs.sensors' | translate }}
        </div>
      </ng-template>
      <!-- ignition parameter -->
      <div class="label text-14 fw-500 mb-8">
        {{ 'tsp-admin.devices.tabs.ignition' | translate }}
      </div>
      <p-dropdown
        [options]="ignitionOptions"
        appendTo="body"
        optionLabel="label"
        formControlName="ignitionParameter"
        appendTo="body"
        class="h--40 mb-8"
      >
        <ng-template let-param pTemplate="item">
          <div class="option-item text-14">
            <div>{{ param.label }}</div>
          </div>
        </ng-template>
      </p-dropdown>
      <div class="last-updated-message text-14 mb-16">
        <app-icon-log
          class="icon mr-8"
          [height]="20"
          [width]="20"
          [color]="'var(--color-gray-500)'"
        ></app-icon-log>
        <span>
          {{ 'tsp-admin.devices.tabs.most-recent-value' | translate }}:
          @if (ignitionParameter?.data) {
            <span>
              @if (ignitionParameter?.data?.value === true) {
                True
              } @else if (ignitionParameter?.data?.value === false) {
                False
              } @else {
                {{ ignitionParameter?.data?.value }}
              }
              @if (ignitionParameter?.data?.ts) {
                <span>
                  ({{
                    (ignitionParameter?.data?.ts || 0) * 1000 | formatDate: 'grid-date-time-sec'
                  }})
                </span>
              }
            </span>
          } @else {
            <span> {{ 'tsp-admin.devices.tabs.never-received' | translate }} </span>
          }
        </span>
      </div>

      <!-- odometer parameter -->
      <div class="label text-14 fw-500 mb-8">
        {{ 'tsp-admin.devices.tabs.odometer' | translate }}
      </div>
      <div
        class="odometer"
        [class]="{
          'mb-12': odometerSource === OdometerSourceTypes.telemetry,
          'mb-16': odometerSource !== OdometerSourceTypes.telemetry,
        }"
      >
        <p-selectButton
          formControlName="odometerSource"
          [options]="[
            {
              value: OdometerSourceTypes.calculator,
              label: 'tsp-admin.devices.tabs.gps-calculated' | translate,
            },
            {
              value: OdometerSourceTypes.telemetry,
              label: 'tsp-admin.devices.tabs.parameter-based' | translate,
            },
          ]"
          optionValue="value"
          optionLabel="label"
        ></p-selectButton>
      </div>

      @if (odometerSource === OdometerSourceTypes.telemetry) {
        <p-dropdown
          [options]="odometerOptions"
          appendTo="body"
          optionLabel="label"
          formControlName="odometerParameter"
          appendTo="body"
          class="h--40 mb-8"
        >
          <ng-template let-param pTemplate="item">
            <div class="option-item text-14">
              <div>{{ param.label }}</div>
            </div>
          </ng-template>
        </p-dropdown>
      }

      @if (odometerSource === OdometerSourceTypes.telemetry) {
        <div class="last-updated-message text-14 mb-16">
          <app-icon-log
            class="icon mr-8"
            [height]="20"
            [width]="20"
            [color]="'var(--color-gray-500)'"
          ></app-icon-log>
          <span>
            {{ 'tsp-admin.devices.tabs.most-recent-value' | translate }}:
            @if (odometerParameter?.data) {
              <span>
                {{ formatOdometerValue(odometerParameter?.data?.value) }} km
                @if (odometerParameter?.data?.ts) {
                  <span>
                    ({{
                      (odometerParameter?.data?.ts || 0) * 1000 | formatDate: 'grid-date-time-sec'
                    }})
                  </span>
                }
              </span>
            } @else {
              <span> {{ 'tsp-admin.devices.tabs.never-received' | translate }} </span>
            }
          </span>
        </div>
      }
      <!-- battery parameter -->
      <div class="label text-14 fw-500 mb-8">
        {{ 'tsp-admin.devices.tabs.vehicle-battery' | translate }}
      </div>
      <p-dropdown
        [options]="batteryOptions"
        appendTo="body"
        optionLabel="label"
        formControlName="batteryParameter"
        appendTo="body"
        class="h--40 mb-8"
      >
        <ng-template let-param pTemplate="item">
          <div class="option-item text-14">
            <div>{{ param.label }}</div>
          </div>
        </ng-template>
      </p-dropdown>
      <div class="last-updated-message text-14">
        <app-icon-log
          class="icon mr-8"
          [height]="20"
          [width]="20"
          [color]="'var(--color-gray-500)'"
        ></app-icon-log>
        <span>
          {{ 'tsp-admin.devices.tabs.most-recent-value' | translate }}:
          @if (batteryParameter?.data) {
            <span>
              {{ batteryParameter?.data?.value }} V
              @if (batteryParameter?.data?.ts) {
                <span>
                  ({{
                    (batteryParameter?.data?.ts || 0) * 1000 | formatDate: 'grid-date-time-sec'
                  }})
                </span>
              }
            </span>
          } @else {
            <span> {{ 'tsp-admin.devices.tabs.never-received' | translate }} </span>
          }
        </span>
      </div>
    </p-tabPanel>
    <p-tabPanel [disabled]="!deviceType">
      <ng-template pTemplate="header">
        <div class="navigation-item text-14 fw-600">
          {{ 'tsp-admin.devices.tabs.commands' | translate }}
        </div>
      </ng-template>
      @if (deviceType) {
        @if (commandDescription) {
          @if (isLoadingCommandList$ | async) {
            <p-skeleton width="100%" height="40px" borderRadius="6px" styleClass="mb-16" />
            <p-skeleton width="100%" height="40px" borderRadius="6px" styleClass="mb-16" />
            <p-skeleton width="100%" height="40px" borderRadius="6px" />
          } @else if (isErrorLoadCommandList$ | async) {
            <div class="no-command-load-error">
              {{ 'tsp-admin.devices.tabs.error-get-commands-list' | translate }}
            </div>
          } @else if (((commandListToAssign$ | async) || []).length) {
            @if (((commandListToAssign$ | async) || []).length > 5) {
              <div class="search-container p-fluid mb-4">
                <p-iconField class="h--40">
                  <p-inputIcon>
                    <app-icon-search [height]="20" [width]="20"></app-icon-search>
                  </p-inputIcon>
                  <input
                    [(ngModel)]="commandSearch"
                    [placeholder]="'button.search' | translate"
                    class="search-input text-14"
                    pInputText
                    type="text"
                    [ngModelOptions]="{ standalone: true }"
                  />
                  <p-inputIcon *ngIf="commandSearch?.length">
                    <app-icon-close
                      class="clickable"
                      (click)="commandSearch = ''"
                      [color]="'var(--color-gray-500)'"
                    ></app-icon-close>
                  </p-inputIcon>
                </p-iconField>
              </div>
              <div class="horizontal-spacer--12"></div>
            }

            @if (filteredCommandListToAssign.length) {
              <div class="commands-list scroll-wrapper scrollbar-gray">
                @for (commandData of filteredCommandListToAssign; track commandData.id) {
                  <div class="commands-list__item" formGroupName="assignCommandIdsStatuses">
                    <p-inputSwitch [formControlName]="commandData.id" />
                    <div class="commands-list__label text-14 fw-500">{{ commandData.name }}</div>
                    <div class="commands-list__edit-icon">
                      <i
                        class="pi pi-pencil"
                        (click)="onEditCommandClicked(commandData)"
                        [class]="{
                          disabled: !!(disableMode$ | async),
                          clickable: (disableMode$ | async) === false,
                        }"
                      ></i>
                    </div>
                  </div>
                }
                <p-button
                  [severity]="'secondary'"
                  (click)="onAddCommandClick()"
                  class="text-14 fw-600 h--40 p--18"
                  [disabled]="!!(disableMode$ | async)"
                >
                  <app-icon-plus class="icon" [height]="20" [width]="20"></app-icon-plus>
                  {{ 'tsp-admin.devices.commands.add-command' | translate }}
                </p-button>
              </div>
            } @else {
              <div class="command-search">
                <app-icon-no-search
                  [width]="32"
                  [height]="32"
                  class="command-search__icon"
                ></app-icon-no-search>
                <div class="command-search__summary">
                  {{ 'search.no-data-title' | translate }}
                </div>
                <div class="command-search__description">
                  {{ 'search.no-data-description' | translate }}
                </div>
              </div>
              <p-button
                [severity]="'secondary'"
                (click)="onAddCommandClick()"
                class="text-14 fw-600 h--40 p--18 mt-24"
                [disabled]="!!(disableMode$ | async)"
              >
                <app-icon-plus class="icon" [height]="20" [width]="20"></app-icon-plus>
                {{ 'tsp-admin.devices.commands.add-command' | translate }}
              </p-button>
            }
          } @else {
            <div class="no-commands">
              <div class="no-commands__summary">
                {{ 'tsp-admin.devices.tabs.no-commands.summary' | translate }}
              </div>
              <div class="no-commands__description mb-24">
                {{ 'tsp-admin.devices.tabs.no-commands.description' | translate }}
              </div>
              <p-button
                [severity]="'secondary'"
                (click)="onAddCommandClick()"
                class="text-14 fw-600 h--40 p--18"
                [disabled]="!!(disableMode$ | async)"
              >
                <app-icon-plus class="icon" [height]="20" [width]="20"></app-icon-plus>
                {{ 'tsp-admin.devices.commands.add-command' | translate }}
              </p-button>
            </div>
          }
        } @else {
          <div class="no-command-support">
            <div class="no-command-support__summary">
              {{ 'tsp-admin.devices.commands.not-available' | translate }}
            </div>
            <div class="no-command-support__description">
              {{ 'tsp-admin.devices.commands.not-available-description' | translate }}
            </div>
          </div>
        }
      }
    </p-tabPanel>
  </p-tabView>
  @if ((errorMessageKey$ | async) || false) {
    <section class="errors-section">
      <div class="errors-section-icon">
        <i class="pi pi-times-circle"></i>
      </div>
      <div>
        {{ (errorMessageKey$ | async) || '' | translate: { fleetName: fleet?.name } }}
      </div>
    </section>
  }
</form>

<div class="footer flex">
  <p-button
    (click)="onCancel()"
    [disabled]="!!(disableMode$ | async)"
    [severity]="'secondary'"
    class="cancel text-14 fw-600 w50 p--8 h--40"
    type="submit"
  >
    {{ 'button.cancel' | translate }}
  </p-button>

  <p-button
    (click)="onSubmit()"
    [disabled]="(modelWasChanged$ | async) === false || !isValidForm || !!(disableMode$ | async)"
    [loading]="(isLoading$ | async) || false"
    class="submit text-14 fw-600 w50 h--40"
    type="submit"
  >
    @switch (newDeviceMode) {
      @case (true) {
        {{ 'button.add' | translate }}
      }
      @case (false) {
        {{ 'button.save' | translate }}
      }
    }
  </p-button>
</div>

@if (commandDescription) {
  <app-command-edit
    (cancelEvent)="onCancelEditCommand()"
    (submitEvent)="editCommand($event)"
    [command]="commandsListModel.state.selectedItem$ | async"
    [opened]="!!(commandsListModel.state.selectedItem$ | async)"
    [processing]="commandsListModel.isEditingCommand"
    [errorMessage]="commandsListModel.editingErrorMessage"
  ></app-command-edit>

  <ng-container *ngIf="showAddCommandDialog$ | async as showAddDialog">
    <app-add-new-command
      [command]="{ externalDeviceTypeId: deviceType?.value }"
      (cancelEvent)="onCancelAddCommand()"
      (submitEvent)="addCommand($event)"
      [opened]="showAddDialog"
      [processing]="commandsListModel.isCreatingCommand"
      [errorMessage]="commandsListModel.creationErrorMessage"
    ></app-add-new-command>
  </ng-container>
}
