import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core'
import { TspDeviceListData } from '@ti-platform/contracts'

@Component({
  selector: 'app-add-new-device',
  templateUrl: 'device-add.component.html',
  styleUrl: 'device-add.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeviceAddComponent {
  @Input() opened = false
  @Input() device: Partial<TspDeviceListData> = {}

  @Output() deviceAddedEvent = new EventEmitter<boolean>()
  @Output() cancelEvent = new EventEmitter<void>()

  public onSubmit(result: boolean) {
    this.opened = false
    this.deviceAddedEvent.emit(result)
  }

  public onCancel() {
    this.opened = false
    this.cancelEvent.emit()
  }
}
