import { Point } from 'typeorm'
import { Device } from './device'
import { Fleet } from './fleet'
import { MediaFile } from './media-file'
import { UserProfile } from './user-profile'
import { Vehicle } from './vehicle'

export enum MediaRequestType {
  Video,
  Photo,
}

export enum MediaRequestStatus {
  InQueue,
  Failed,
  Expired,
  SucceedInResaving,
  SucceedFinished,
}

export interface MediaRequest {
  id: number
  type: MediaRequestType
  requestData: Record<string, any>
  startDate: Date
  endDate: Date
  status: MediaRequestStatus
  mediaFileId: string
  triggeredAlertId: string
  requestGroupId: string
  location: Point
  address: string
  fleetId: number
  tspId: number
  vehicleId: string
  deviceId: string
  externalDeviceId: number
  createdAt: Date
  createdBy: string
  mediaFile: MediaFile
  fleet: Fleet
  vehicle: Vehicle
  device: Device
  requester: UserProfile
}
